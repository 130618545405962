.link-manager-container{
	width: 100%;
	display: flex;
	flex-direction: column;
}

.link-manager-container h1{
	text-align: center;
	width: 100%;
}

.link-form{
	display: grid;
	grid-template-columns: 50% 30% 20%;
	gap: 4px;
	padding: 16px;
}

.link-list{
	padding: 16px;
	
}

.link-header{
	font-weight: 600;
	font-size: 110%;
}

.link-item{
	display: grid;
	grid-template-columns: 70% 20% 10%;
	gap: 4px;
	margin-bottom: 4px;
	padding: 2px;
}

.link-item:hover{
	background-color: #ebebeb;
}