.color-pallete{
	background-color: #cca235;
	background-color: #e6bd26;
	background-color: #f7f7f5;
	background-color: #ebebeb;
}

@font-face {
	font-family: "Quicksand";
	src: url("./assets/fonts/Quicksand-Regular.ttf");
}

@font-face {
	font-family: "BioRhyme";
	src: url("./assets/fonts/BioRhyme-Regular.ttf");
}

#root{
	display: flex;
	box-sizing: border-box;
	width: 100%;
}

body{
	margin: 0;
	display: flex;
	background-color: #f7f7f5;
	font-family: "BioRhyme";
	box-sizing: border-box;
}

.pane{
	background-color: #ebebeb;
	padding: 16px;
	border-top: 4px solid #e6bd26;
	border-bottom: none;
	border-left: none;
	border-right: none;
}

.ant-btn-primary{
	background-color: #cca235;
	border-color: #cca235;
	border-radius: 0px;
	font-family: "Quicksand";
	font-weight: 600;
}

:where(.css-dev-only-do-not-override-ph9edi).ant-btn-primary:not(:disabled):hover {
	color: #fff;
	background-color: #e6bd26;
}