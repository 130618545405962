.settings-page-container{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
}

.settings-form{
	width: 600px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 4px;
}