.login-container{
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.login-form{
	width: 600px;
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.login-form h1{
	text-align: center;
}

.forgot-wrapper{
	display: flex;
	justify-content: right;
	font-size: 90%;
	margin-top: 16px;
}