.reports-page-container{
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px;
	box-sizing: border-box;
}

.reports-page-container h1{
	text-align: center;
}

.filters-form{
	display: grid;
	grid-template-columns: 33% 33% 33%;
}

.filter-cell{
	display: flex;
	flex-direction: column;
	margin: 8px;
}

.btn-filter-wrapper{
	display: flex;
	justify-content: center;
}
.btn-filter-wrapper button{
	width: 25%;
}

.report-header{
	font-size: 120%;
	margin-top: 8px;
	margin-bottom: 8px;
}

.reports-container{
	width: 100%;
	height: 100%;
}

.download-container{
	margin-top: 65px;
	display: flex;
	justify-content: center;
}

.big-chart-container{
	width: 100%;
	height: 100%;
}

.chart-container{
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 50% 50%;
}

.sku-chart{
	width: 800px !important;
	height: 1000px !important;
}

.chart-wrapper{
	width: 100%;
	height: 500px;
}

@media (max-width:1600px){

	.chart-container{
		grid-template-columns: 100%;
	}
}

@media (max-width:600px){

	.filters-form{
		display: grid;
		grid-template-columns: 50% 50%;
	}
	.btn-filter-wrapper button {
		width: 50%;
	}
}


@media (max-width:400px){

	.filters-form{
		display: grid;
		grid-template-columns: 100%;
	}
	.btn-filter-wrapper button {
		width: 75%;
	}
}
