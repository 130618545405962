.dashboard-container{
	display: flex;
	flex-direction: column;
	width: 100%;
}

.dashboard-menu{
	background-color: #ebebeb;
	border-top: none;	
	border-bottom: none;
	border-left: none;
	border-right: 4px solid #e6bd26;
	padding: 16px;
	height: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	min-width: 300px;
}

.dashboard-header{
	font-size: 130%;
	font-weight: 600;
	margin-bottom: 16px;
}

.menu-item{
	text-decoration: underline;
	margin-bottom: 4px;
}
.menu-item:hover{
	cursor: pointer;
	font-style: italic;
	font-weight: 600;
}

.horizontal-menu{
	width: 100%;
}